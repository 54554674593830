import $ from "jquery";

$(function () {
    $(".category-filter-dropdown").on("change", function(event: { currentTarget: HTMLSelectElement; }) {
        // debugger;

        // check if 'this' is an HTMLSelectElement
        if(event.currentTarget instanceof HTMLSelectElement) {
            let selectElement = event.currentTarget;

        const url = $(selectElement).val(); // get selected value
        if (url) {
            // require a URL
            window.location = url; // redirect
        }
        return false;}
    });

    $(".year-filter-dropdown").on("change", function(event: { currentTarget: HTMLSelectElement; }) {
        // check if 'this' is an HTMLSelectElement
        if(event.currentTarget instanceof HTMLSelectElement) {
            let selectElement = event.currentTarget;

        const url = $(selectElement).val(); // get selected value
        if (url) {
            console.log('url for year archive',url);
            // require a URL
            window.location = url; // redirect
        }
        return false;
        }
    });

    $("#filter-audience").on("change", function( event: { currentTarget: HTMLSelectElement; }) {
        // check if 'this' is an HTMLSelectElement
        if(event.currentTarget instanceof HTMLSelectElement) {
            let selectElement = event.currentTarget;

        const url = $(selectElement).val(); // get selected value
        if (url) {
            // require a URL
            window.location = url; // redirect
        }
        return false;}
    });
})
