document.addEventListener('DOMContentLoaded', function() {
  const readMoreNotice = document.querySelector('#read-more-notice');
  const readMoreContent = document.querySelector('#read-more-content');

  if (readMoreNotice instanceof HTMLElement && readMoreContent instanceof HTMLElement ) {
    readMoreNotice.addEventListener('click', function () {
      // console.log('Show the additional content.');
      readMoreContent.style.height = 'auto';
      readMoreNotice.style.display = 'none';
    });
  }
});
