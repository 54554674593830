const expandableContentWrapper = document.querySelector('.expandable-content');

if (expandableContentWrapper) {
  const toggleLinks = expandableContentWrapper.querySelectorAll('.expandable-content__toggle-link');

  toggleLinks.forEach((toggleLink) => {
    if (!toggleLink.classList.contains('active')) {
      const itemNo = toggleLink.getAttribute('data-itemno');
      const target = expandableContentWrapper.querySelector(`.expandable-content__text--itemno-${itemNo}`);
      if (target && target instanceof HTMLElement) {
        target.style.display = 'none';
      }
    }
  });

  toggleLinks.forEach((toggleLink) => {
    toggleLink.addEventListener('click', (event) => {
      event.preventDefault();

      const itemNo = toggleLink.getAttribute('data-itemno');
      const target = expandableContentWrapper.querySelector(`.expandable-content__text--itemno-${itemNo}`) ?? null;

      if (toggleLink.classList.contains('active')) {
        toggleLink.classList.remove('active');
        toggleLink.querySelector('.glyphicon')?.classList.remove('glyphicon-chevron-up');
        toggleLink.querySelector('.glyphicon')?.classList.add('glyphicon-chevron-down');
        if (target && target instanceof HTMLElement) {
          target.style.display = 'none';
        }
      } else {
        toggleLink.classList.add('active');
        toggleLink.querySelector('.glyphicon')?.classList.remove('glyphicon-chevron-down');
        toggleLink.querySelector('.glyphicon')?.classList.add('glyphicon-chevron-up');
        if (target && target instanceof HTMLElement) {
          target.style.display = '';
        }
      }
    });
  });
}
