// @ts-ignore
import Flickity from 'flickity';

// wait for the DOM to be loaded


// check if element exists
if (document.querySelector('.primary-feature-carousel')) {

    const carousel = new Flickity('.primary-feature-carousel', {
        // options
        wrapAround: true,
        // lazyLoad: true,
        contain: true,
        cellAlign: 'left',
        adaptiveHeight: false,
        prevNextButtons: true,
        pageDots: false,
        // imagesLoaded: true,
        resize: true
    });

    // listen for a window resize event
    window.addEventListener('resize', function() {
        // resize the carousel
        carousel.resize();
    });
    document.addEventListener('DOMContentLoaded', function() {
        carousel.resize();
     });
}

// check if element exists .advanced-carousel
if (document.querySelector('.advanced-carousel')) {

        const carousel = new Flickity('.advanced-carousel', {
            // options
            wrapAround: true,
            // lazyLoad: true,
            contain: true,
            cellAlign: 'left',
            adaptiveHeight: false,
            prevNextButtons: true,
            pageDots: false,
            // imagesLoaded: true,
            resize: true
        });

        // listen for a window resize event
        window.addEventListener('resize', function() {
            // resize the carousel
            carousel.resize();
        });
        window.addEventListener('load', function() {
            // resize the carousel
            carousel.resize();
        }
      );

        // document.addEventListener('DOMContentLoaded', function() {
        //       // alert('resize')
        //    carousel.resize();
        // });
}

// check if element exists .icon-carousel
if (document.querySelector('.icon-carousel')) {

            const carousel = new Flickity('.icon-carousel', {
            // options
            wrapAround: true,
            lazyLoad: 2,
            contain: true,
            adaptiveHeight: true,
            pageDots: false,
            imagesLoaded: true,
            groupCells: true
        });

            // listen for a window resize event
            window.addEventListener('resize', function() {
                // resize the carousel
                carousel.resize();
            });
           //  document.addEventListener('DOMContentLoaded', function() {
           //    carousel.resize();
           // });
            window.addEventListener('load', function() {
                  // resize the carousel
                  carousel.resize();

            });
}

// check if element exists .siss-carousel
if (document.querySelector('.siss-carousel')) {

    const carousel = new Flickity('.siss-carousel', {
            // options
            wrapAround: true,
            // lazyLoad: 2,
            // contain: true,
            adaptiveHeight: false,
            pageDots: false,
            setGallerySize: true
        });

    // listen for a window resize event
    window.addEventListener('resize', function() {
        // resize the carousel
        carousel.resize();
    });
    // document.addEventListener('DOMContentLoaded', function() {
    //     carousel.resize();
    //  });
    window.addEventListener('load', function() {
         // resize the carousel
         carousel.resize();

    });
}

// check if element exists .blc-carousel
if (document.querySelector('.blc-carousel')) {

    const carousel = new Flickity('.blc-carousel', {
            // options
            wrapAround: true,
            lazyLoad: 2,
            contain: true,
            adaptiveHeight: true,
            imagesLoaded: true
        });

    // listen for a window resize event
    window.addEventListener('resize', function() {
        // resize the carousel
        carousel.resize();
    });
    // document.addEventListener('DOMContentLoaded', function() {
    //     carousel.resize();
    //  });
      window.addEventListener('load', function() {
         // resize the carousel
         carousel.resize();
      });
}


